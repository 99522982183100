import { useContext, useEffect, useRef } from "react";
import { Paginator } from "components/Shared";
import { Scaffold } from "layouts";
import * as S from "./styles";
import {
  ReleasedInvoicesList,
  ReleasedInvoicesFilter,
  Navigation,
  InvoiceModal,
  Ref as InvoiceModalRef,
} from "components/Pages/Invoices/";
import { ReleasedContext } from "contexts/ReleasedContext";
import { Loading } from "components/Shared/Loading";
import { DownloadXLS } from "components/Shared/DownloadXLS";
import { DropdownMenuInvoices } from "components/Pages/Invoices/ReleasedInvoices/DropdownMenu";

export const ListReleased = () => {
  const invoiceModalRef = useRef<InvoiceModalRef>(null);
  const { pagination, onPageChange, onSort, loading, query, resetSelectedList } =
    useContext(ReleasedContext);

    useEffect(() => {
        return () => {
            resetSelectedList()
        }
    }, [])

  return (
    <Scaffold>
      <S.Container>
        <Navigation />
        <S.Panel>
          <InvoiceModal ref={invoiceModalRef} />
          <S.FilterContainer>
            <ReleasedInvoicesFilter />
            {loading && <Loading />}
            <S.FilterContent>
              <DownloadXLS
                archiveName="INBOUND-RELATORIO-TRACKING"
                type="Liberada para Tracking"
                query={query}
              />
              <DropdownMenuInvoices />
            </S.FilterContent>
          </S.FilterContainer>
          <ReleasedInvoicesList
            onSort={onSort}
            onSelectInvoice={(invoiceId) =>
              invoiceModalRef.current?.selectInvoice(invoiceId)
            }
          />
          <Paginator onPageChange={onPageChange} pagination={pagination} />
        </S.Panel>
      </S.Container>
    </Scaffold>
  );
};
